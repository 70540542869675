.Sidebar
   background-color: #121212
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8)
   border-radius: 0px 0px 20px 0px
   z-index: 500
   position: fixed
   left: 0
   top: 0
   float: left
   min-height: 100%
   max-width: 200px
   padding: 10px
   margin: 0px

.Sidebar2
   background-color: #113568
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8)
   border-radius: 0px 0px 20px 0px
   z-index: 500
   position: fixed
   left: 0
   top: 0
   float: left
   min-height: 100%
   max-width: 260px
   width: 260px
   padding: 0px
   margin: 0px

.sidebar-img
   padding: 0.1rem
   img
      width: 80%

.sidebar-item
   font-size: 1.0rem
   font-weight: bold
   color: #2C2D35
   line-height: 0.5
   border-radius: 15px
   padding: 1rem 0.85rem
   display: flex
   align-items: center
   justify-content: left
   transition: all 300ms
   margin: 20px
   background-color: #E5E5E5
   &:hover
      background-color: #C1C1C1
      cursor: pointer

.sidebar-item2
   color: #fff
   border-radius: 50px
   padding: 1rem 1rem
   position: absolute
   transition: all 300ms
   bottom: 12%
   left: 0
   right: 0
   margin-left: auto
   margin-right: auto
   width: 34%
   &:hover
      background-color: #C1C1C1

.active
   color: #2C2D35
   background-color: white
   font-weight: bold
   &:hover
      background-color: white

.sidebar-label
   margin-bottom: 0
   padding-left: 8px

.sidebaruser
   color: white
   background-color: #1a1919
   border-radius: 0px 0px 20px 0px
   position: absolute
   bottom: 0
   width: 100%
   height: 10vh
   padding: 0.5rem
   display: flex
   justify-content: center
   align-items: center
   p
      margin-top: 4%
      margin-left: 17%
      font-size: 1.0rem

.sidebaruser2
   display: flex
   justify-content: center
   align-items: center
   color: white
   background-color: #0A254B
   border-radius: 0px 0px 20px 0px
   position: absolute
   bottom: 0
   width: 100%
   height: 10vh
   padding: 0.5rem
   line-height: 40px

   p
      //margin-top: 4%
      //margin-left: 17%
      //bottom: 10%
      //font-size: 1.0rem

.rounded-circle
   border-radius: 50% !important
   margin-top: 10%
   width: 250%

.sidebar-itemGroup
   font-size: 1.0rem
   font-weight: bold
   color: #2C2D35
   line-height: 0.5
   border-radius: 15px
   padding: 1rem 0.85rem
   display: flex
   align-items: center
   justify-content: left
   transition: all 300ms
   margin: 20px
   background-color: #E5E5E5
   &:hover
      background-color: #E5E5E5
      cursor: pointer
   &:active
      background-color: #E5E5E5

.sidebar-itemConfig
   font-size: 1.0rem
   font-weight: bold
   color: #2C2D35
   line-height: 0.5
   border-radius: 15px
   padding: 1rem 0.85rem
   display: flex
   align-items: center
   justify-content: left
   transition: all 300ms
   background-color: #E5E5E5
   margin-top: 10px
   &:hover
      background-color: #C1C1C1

@media screen and ( max-width: 700px )
   .sidebar-item
      font-size: 0rem

   .Sidebar
      width: 5vw
      min-width: 80px

   .Sidebar2
      width: 5vw
      min-width: 80px

   .sidebaruser
      p
         margin-top: -20px
         font-size: 0.8rem

   .sidebaruser2
      p
         margin-top: -20px
         font-size: 0.8rem

   .sidebar-item2
      width: 76%

@media screen and ( max-width: 1600px )
   .sidebar-item
      padding: 10px 15px
      font-size: 14px
      svg
         width: 20px

   .sidebar-itemGroup
      padding: 10px 15px
      font-size: 14px
      svg
         width: 20px

   .sidebar-itemConfig
      font-size: 14px
      padding: 7px 15px
      margin-left: -8px
      margin-top: 5px
      svg
         width: 17px

   .Sidebar2
      max-width: 200px
      width: 200px

   .sidebaruser2
      font-size: 14px
      height: 50px

.CreateUserPage
   display: block
   padding-bottom: 30px
.table
   box-shadow: 0px 0px 10px 0px #00000040
   border-radius: 10px
   margin-bottom: 50px !important
   thead
      background-color: #113568
      color: white
   td, th
      padding: 1rem 1rem !important

.avatar
   margin-right: 10px
   vertical-align: middle
   width: 25px
   border-radius: 50%


.pagination
   font-size: 15px
   display: inline-block

   span
      cursor: pointer
      border: 1px solid #E0E0E0
      color: #113568
      float: left
      padding: 5px 10px
      text-decoration: none

   a
      border: 1px solid #E0E0E0
      color: black
      float: left
      padding: 5px 10px
      text-decoration: none

   .active 
      background-color: #113568
      color: white
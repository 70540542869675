.Filter
   height: 65px
   display: block
   padding-left: 20px
   padding-right: 20px

.botonCreateUser
   width: 77px
   border: 2px solid white
   border-radius: 10px
   height: 50px
   background-color: #113568
   margin-right: 8px

.botonCreateUser:hover
   background-color: #0A254B

.filtroSearch
   background-color: #113568
   color: white

.filtroSearch:hover
   color: white
   background-color: #0A254B
.modal-containerHistorial {
   position: fixed;
   top: 10%;
   left: 40%;
   width: 800px;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 9999;
}

.modal-background {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   /* Color de fondo con opacidad */
}

.modal-grupoHistorial {
   display: flex ;
   flex-direction: column;
   justify-content: center;
   /* align-items: center; */
   position: relative;
   background-color: white;
   padding-bottom: 20px;
   border-radius: 15px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
   width: 600px;
}

.input-modal{
   margin-top: 20px;
   margin-bottom: 20px;
}

.headerFormulario{
   width: 100%;
   color: white;
   background-color: #113568;
   padding: 15px;
   margin-bottom: 10px;
   display: flex;
   align-items: center;
   justify-content: center;
}

/*Poner border radius solo a la esquina superior*/
.headerFormulario{
   border-bottom-left-radius: 0;
   border-bottom-right-radius: 0;
}

.headerFormulario:first-child{
   border-top-left-radius: 14px;
   border-top-right-radius: 14px;
}

.headerFormulario:last-child{
   border-top-left-radius: 0;
   border-top-right-radius: 0;
}

.modal-grupo input{
   border: 1px solid gray;
   width: 100%;
   height: 50px;
   border-radius: 6px;
   padding: 10px;
}

.grupo-botones{
   display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.boton-modal{
   width: 120px;
   margin-left: 20px !important;
   margin-right: 20px !important;
}

/* Agrega más estilos según sea necesario */

.formularioCerrarCCH{
   padding: 40px 54px;
   display: grid;
   grid-template-columns: repeat(2, 2fr);
   gap: 20px;
   justify-content: center;
}
.CreateUserPage
   display: block
   padding-bottom: 30px

.profilePicture
   cursor: pointer
   border: 1px dashed #b4b4b4
   width: 100%
   height: 110px
   color: #b4b4b4
   text-align: center
   p
      color: #b4b4b4

.imgProfile
   display: block
   margin: auto
   text-align: center
   width: auto
   height: 100%
   max-width: 18vw

.box
   border-radius: 10px
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25)

.boxBarra
   display: flex
   flex-direction: row
   justify-content: space-between
   align-items: center
   margin-bottom: 15px

.box h3
   color: #000
   font-weight: normal !important
   text-align: left
   margin-bottom: 0px
   margin-left: 10px

.box button
   width: 80px
   border: 2px solid white
   border-radius: 10px
   height: 50px
   background-color: #113568
   margin-right: 20px

.box button:hover
   background-color: #0A254B

.formularioUsuarios
   background-color: white
   width: 100%
   border-radius: 20px
   box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.2)

.headerProducto
   color: white
   background-color: #113568
   padding: 12px
   display: flex
   align-items: center
   justify-content: center

.headerFormulario
   color: white
   background-color: #113568
   padding: 0px
   display: flex
   align-items: center
   justify-content: center

.headerFormulario button:hover
   background-color: #C1C1C1
   border: 2px solid #C1C1C1
   transition: all 200ms

.formulario
   padding: 40px 100px
   display: grid
   grid-template-columns: repeat(3, 2fr)
   gap: 20px
   justify-content: center
   padding-bottom: 0px

.formularioNota
   padding: 20px 100px
   display: grid
   grid-template-columns: repeat(1, 2fr)
   gap: 20px
   justify-content: center
   padding-bottom: 110px

.formularioNota textarea
   width: 98.5%
   height: 100px
   resize: none
   border: 1px solid gray
   border-radius: 6px
   padding: 10px

.formulario input
   border: 1px solid gray
   width: 95%
   height: 50px
   border-radius: 6px
   padding: 10px

.formulario select, .formularioNota select
   border: 1px solid gray
   width: 95%
   height: 50px
   border-radius: 6px
   padding: 10px

.formulario label, .formularioNota label
   font-size: 18px
   margin-bottom: 5px

.form-group
   margin-top: 20px !important

/*Poner border radius solo a la esquina superior*/
.headerFormulario
   border-bottom-left-radius: 0
   border-bottom-right-radius: 0

.headerFormulario:first-child
   border-top-left-radius: 15px
   border-top-right-radius: 15px

.headerFormulario:last-child
   border-top-left-radius: 0
   border-top-right-radius: 0

/*Diseño para el apartado del modal*/
.formularioCliente
   background-color: white
   width: 100%
   border-radius: 20px
   box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.2)

.referencias
   width: 100%
   height: auto

.referenciasBoton button
   float: right
   width: 80px
   border: 2px solid white
   border-radius: 10px
   height: 50px
   background-color: #113568
   margin-right: 20px
   margin: 15px 40px

.referenciasBoton button:hover
   background-color: #0A254B

.dataTable
   padding: 0px 40px 0px 40px
   display: flex
   align-items: center
   flex-direction: column
   width: 100%

.compraTotal
   /*float: right*/
   display: flex
   flex-direction: row
   justify-content: center
   align-items: center
   margin: 40px

.compraTotal input
   border: 1px solid gray
   width: 50%
   height: 50px
   border-radius: 6px
   padding: 10px
   margin-left: 10px

.divPrecios
   display: flex
   align-items: center
   justify-content: center

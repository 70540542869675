.CreateClientePage
   display: block
   padding-bottom: 30px

.profilePicture
   cursor: pointer
   border: 1px dashed #b4b4b4
   width: 100%
   height: 110px
   color: #b4b4b4
   text-align: center
   p
      color: #b4b4b4

.imgProfile
   display: block
   margin: auto
   text-align: center
   width: auto
   height: 100%
   max-width: 18vw

.contenedor
   display: flex
   flex-direction: column
   justify-content: center
   align-items: center

.box
   border-radius: 10px
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25)

.boxBarra
   display: flex
   flex-direction: row
   justify-content: space-between
   align-items: center
   margin-bottom: 15px

.box h3
   color: #000
   font-weight: normal !important
   text-align: left
   margin-bottom: 0px
   margin-left: 10px

.box button
   width: 80px
   border: 2px solid white
   border-radius: 10px
   height: 50px
   background-color: #ffc107
   border-color: #ffc107
   margin-right: 20px

.formularioCliente
   background-color: white
   width: 100%
   border-radius: 20px
   box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.2)

.formularioCobranza
   background-color: white
   width: 100%
   border-radius: 20px
   box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.2)

.headerFormulario
   color: white
   background-color: #113568
   padding: 15px
   display: flex
   align-items: center
   justify-content: center

.headerFormulario h3
   margin: 0



.formulario
   padding: 40px 100px
   display: grid
   grid-template-columns: repeat(3, 2fr)
   gap: 20px
   justify-content: center

.formularioDM
   padding: 40px 100px
   display: grid
   grid-template-columns: repeat(2, 2fr)
   gap: 20px
   justify-content: center

.formularioCobros
   padding: 40px 80px
   display: grid
   grid-template-columns: repeat(2, 2fr)
   gap: 20px
   justify-content: center

.formularioCobrosTrabajo
   padding: 40px 80px
   display: grid
   grid-template-columns: repeat(2, 2fr)
   gap: 20px
   justify-content: center

.formularioLugar
   width: 40%
   display: grid
   grid-template-columns: repeat(2, 2fr)

.formularioHora
   width: 100%
   padding-top: 0px
   padding-left: 20px
   display: grid
   grid-template-columns: repeat(2, 2fr)

.formulario input, .formularioDM input, .formularioHora input
   border: 1px solid gray
   width: 95%
   height: 50px
   border-radius: 6px
   padding: 10px

.formularioHora input[type="time"]
   font-size: 18px

.formularioHora input
   width: 90% !important


.formularioCobros input
   border: 1px solid gray
   width: 15%
   height: 50px
   border-radius: 6px
   padding: 10px

.formularioCobros textarea, .formularioCobrosTrabajo textarea
   width: 100%
   height: 153px
   resize: none
   border: 1px solid gray
   border-radius: 6px
   padding: 10px

.formulario select, .formularioCobros select
   border: 1px solid gray
   width: 95%
   height: 50px
   border-radius: 6px
   padding: 10px

.formulario label, .formularioDM label, .formularioCobros label, .formularioHora label, .formularioCobrosTrabajo label
   font-size: 18px
   margin-bottom: 5px

.cobros
   display: flex
   align-items: center

.cobros input
   width: 8%
   margin-left: 10px

/*Poner border radius solo a la esquina superior*/
.headerFormulario
   border-bottom-left-radius: 0
   border-bottom-right-radius: 0

.headerFormulario:first-child
   border-top-left-radius: 15px
   border-top-right-radius: 15px

.headerFormulario:last-child
   border-top-left-radius: 0
   border-top-right-radius: 0

.referencias
   width: 100%
   height: auto

.referenciasBoton button
   float: right
   width: 80px
   border: 2px solid white
   border-radius: 10px
   height: 50px
   background-color: #113568
   margin-right: 20px
   margin: 15px 40px

.referenciasBoton button:hover
   background-color: #0A254B

.dataTable
   padding: 0px 40px 80px 40px
   display: flex
   align-items: center
   flex-direction: column
   width: 100%

.PasswordChangeSuccessPage
   height: 100vh

.back
   position: fixed
   width: 100%
   height: 100%
   z-index: -1
   background-size: cover
   background-repeat: no-repeat
   background-image: url("../../../assets/back.jpg")

.form
   min-width: 350px
   width: 25vw
   padding: 30px 15px
   background-color: white
   position: absolute
   border-radius: 15px
   box-shadow: 2px 2px 10px 0px #00000040
   top: 15%
   left: 36%   
   transition: all 300ms


@media screen and ( min-width: 1500px )
   .form
      width: 18%
      left: 41%

   
// // esto es para lo ancho
@media screen and ( min-width: 2000px )
   .form
      width: 20%
      left: 40%
      label, input, button, a, .validationEmail, .validationPassword
         font-size: 24px !important


@media screen and ( min-width: 2500px )
   .form
      width: 22%
      left: 39%
      label, input, button, a, .validationEmail, .validationPassword
         font-size: 26px !important

@media screen and ( min-width: 3000px )
   .form
      width: 20%
      left: 40%
      label, input, button, a, .validationEmail, .validationPassword
         font-size: 30px !important


   
// esto es para la altura 
@media screen and ( min-height: 700px )
   .form
      top: 18%


// esto es para la altura 
@media screen and ( min-height: 1350px )
   .form
      top: 22%
   
// esto es para la altura 
@media screen and ( min-height: 1700px )
   .form
      top: 25%

/*.modal-body button {
   font-style: normal;
   font-weight: 700;
   font-size: 16px;

   text-align: center;

   color: #FFFFFF;
   width: 179.23px;
   height: 35px;
   left: 118.84px;
   top: 253.44px;

   background: #113568;
   border-radius: 5px;
}

.modal-body label {

   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 19px;


   text-align: center;

   color: #323232;
}

.modal-header {
   padding: 30px;
}


.modal-header i {
   color: red;
   font-size: 30px;
   font-weight: bold;
   width: 30px;
   cursor: pointer;
}


.modal-header h3 {
   font-style: normal;
   font-weight: 600;
   font-size: 25px;
   color: #4A4A4A;
   margin: 0px;
}


.modal-footer {
   padding-top: 30px;
   padding-bottom: 30px;
}

.modal-body {
   padding-top: 30px;
   padding-bottom: 30px;
}

.divbtn {
   width: 100%;
   text-align: center;
   margin: 0 auto;
}

.divbtn_body {
   text-align: center;
   margin: 0 auto;
}

.modal-footer button {
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   width: 45%;
   height: 35px;
}*/

.modal-container {
   position: fixed;
   top: 20%;
   left: 37%;
   width: 40%;
   height: 100%;
   display: flex;
   justify-content: center !important;
   align-items: center;
   z-index: 9999;
   /* Asegura que el modal esté en la parte superior */
}

.modal-background {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   /* Color de fondo con opacidad */
}

.modal-wrapper {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: relative;
   background-color: white;
   padding-bottom: 20px;
   border-radius: 15px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
   width: 100%;
   /* Sombra */
}

.input-modal{
   margin-top: 20px;
   margin-bottom: 20px;
}

.headerFormulario{
   width: 100%;
   color: white;
   background-color: #113568;
   padding: 15px;
   margin-bottom: 10px;
   display: flex;
   align-items: center;
   justify-content: center;
}

/*Poner border radius solo a la esquina superior*/
.headerFormulario{
   border-bottom-left-radius: 0;
   border-bottom-right-radius: 0;
}

.headerFormulario:first-child{
   border-top-left-radius: 14px;
   border-top-right-radius: 14px;
}

.headerFormulario:last-child{
   border-top-left-radius: 0;
   border-top-right-radius: 0;
}

.modal-wrapper input{
   border: 1px solid gray;
   width: 41.8%;
   height: 50px;
   border-radius: 6px;
   padding: 10px;
}

.modal-botones{
   display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.boton-modal{
   width: 120px;
   margin-left: 20px !important;
   margin-right: 20px !important;
}

/* Agrega más estilos según sea necesario */

.campo-aval{
   width: 60%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
}

.radio-modal{
   display: flex;
   align-items: center;
   width: 100%;
   justify-content: center;
}

.input-aval{
   width: 25% !important;
   margin-left: 10px;
}

.formularioAgregarArticulo{
   padding: 40px 10px;
   display: grid;
   grid-template-columns: repeat(2, 2fr);
   gap: 20px;
   justify-content: center;
}

.formularioAgregarArticulo input{
   border: 1px solid gray;
   width: 100%;
   height: 50px;
   border-radius: 6px;
   padding: 10px;
}

@media screen and ( max-width: 1300px ){
   .modal-container{
      width: 55%;
      left: 32%;
   }

}

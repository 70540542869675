
.back
   position: fixed
   width: 100%
   height: 100%
   z-index: -1
   background-size: cover
   background-repeat: no-repeat
   background-image: url("../../../assets/back.jpg")
   
.box
   position: absolute
   bottom: 0
   left: 0
 
.form
   min-width: 350px
   padding: 30px
   background-color: white
   border-radius: 15px
   box-shadow: 2px 2px 10px 0px #00000040
   position: absolute
   width: 15%
   transition: all 300ms
   


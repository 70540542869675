
.back
   position: fixed
   width: 100%
   height: 100%
   z-index: -1
   background-size: cover
   background-repeat: no-repeat
   background-image: url("../../../assets/back.jpg")

.form
   min-width: 350px
   width: 25vw
   padding: 30px
   background-color: white
   position: absolute
   border-radius: 15px
   box-shadow: 2px 2px 10px 0px #00000040
   
   top: 10%
   left: 35%
   transition: all 300ms


@media screen and ( min-width: 1500px )
   .form
      width: 18%
      left: 41%


// esto es para la altura 
@media screen and ( min-height: 700px )
   .form
      top: 2%


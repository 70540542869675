
.back
   position: fixed
   z-index: -1
   width: 100%
   height: 100%
   background-size: cover
   background-repeat: no-repeat
   background-image: url("../../../assets/back.jpg")

.input_usuario
   border: 2px solid  #113568
   border-radius: 5px
   background-color: #a2c0eb
   padding: 10px

.h2_sesion
   margin-top: -20px
   margin-bottom: 10px
   color: white

.titulo_div
   display: flex
   align-items: center
   justify-content: center
   padding-top: 25px
   background-color: #113568

.boton_sesion
   background-color: #113568
 

   




    


.dashboard{
  margin: 0;
}

.h3{
   color: #FFFAFA; 
   font-family: 'Helvetica Neue', sans-serif; 
   font-size: 30px; 
   font-weight: bold; 
   letter-spacing: -1px; 
   line-height: 1; 
   text-align: center;
}

.title-dashboard{
    color:#323232;
    font-family: Raleway;
    font-weight: 700;
    font-size: 36px;
    /* line-height: 42.20px; */
}

.title-last30{
    color:#4A4A4A;
    /* font-family: 'Open Sans'; */
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    
}

.rounded {
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
}

.hands{
  background-color: #7C6BE1;
  color: white;
  border-radius: 5px;
  width: 52px;
  height: 52px;
}


.mini-stat-icon {
  margin-top:8px;
  /* width: 60px; */
  /* height: 60px; */
  line-height: 60px;
  border-radius: 5px;
  float: left;
  /* margin-right: 10px; */
  color: #FFF;
}

.mini-stat-icon .material-icons{
    color:#fff;
    font-size: 40px;
    text-align: center;
    padding: 10px;
}

.mini-stat-info {
  font-size: 12px;
  padding-top: 2px;
}

.mini-stat-info .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #848484;
  }

  .mini-stat-info .mini-count {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color:#323232;
  }

.mini-stat-info span {
  display: block;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 7px;
}

/* ================ colors =====================*/
.bg-card {
  background-color: #fff !important;
  border: 1px solid #fff;
  color: #848484;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}




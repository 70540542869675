.UsersPage
   display: block
   padding-bottom: 30px

.table
   box-shadow: 0px 0px 10px 0px #00000040
   border-radius: 10px
   thead
      background-color: #113568
      color: white
   td, th
      padding: 1rem 1rem !important

.avatar
   margin-right: 10px
   vertical-align: middle
   width: 25px
   border-radius: 50%

.pagination
   font-size: 15px
   display: inline-block

   span
      cursor: pointer
      border: 1px solid #E0E0E0
      color: #113568
      float: left
      padding: 5px 10px
      text-decoration: none

   a
      border: 1px solid #E0E0E0
      color: black
      float: left
      padding: 5px 10px
      text-decoration: none

   .active
      background-color: #113568
      color: white

.boxHistorial
   border-radius: 10px
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25)

.boxBarraHistorial
   display: flex
   flex-direction: row
   justify-content: space-between
   align-items: center
   margin-bottom: 15px
   height: 60px

.boxHistorial h3
   color: #000
   font-weight: normal !important
   text-align: left
   margin-bottom: 0px
   margin-left: 10px

.boxHistorial button
   width: 80px
   border: 2px solid white
   border-radius: 10px
   height: 50px
   background-color: #113568
   margin-right: 20px

.boxHistorial button:hover
   background-color: #0A254B

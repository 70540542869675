.UpdateUserPage
   display: block
   padding-bottom: 30px

.profilePicture
   cursor: pointer
   border: 1px dashed  #b4b4b4 
   width: 100%
   height: 110px
   color: #b4b4b4
   p
      color: #b4b4b4

.imgProfile
   display: block
   margin: auto
   width: auto
   height: 100% 
   max-width: 18vw 

.box
   border-radius: 10px
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25)

.boxBarra
   display: flex
   flex-direction: row
   justify-content: space-between
   align-items: center
   margin-bottom: 15px

.box h3
   color: #000
   font-weight: normal !important
   text-align: left
   margin-bottom: 0px
   margin-left: 10px

.box button
   width: 80px
   border: 2px solid white
   border-radius: 10px
   height: 50px
   background-color: #ffc107
   border-color: #ffc107
   margin-right: 20px

.formularioUsuarios
   background-color: white
   width: 100%
   border-radius: 20px
   box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.2)

.headerFormulario
   background-color: #113568
   padding: 30px
   display: flex
   align-items: center
   justify-content: right

.headerFormulario button
   width: 5%
   border: 2px solid white
   border-radius: 10px
   height: 50px
   background-color: white

.headerFormulario button:hover
   background-color: #C1C1C1
   border: 2px solid #C1C1C1
   transition: all 200ms

.formulario
   padding: 40px 100px
   display: grid
   grid-template-columns: repeat(3, 2fr)
   gap: 20px
   justify-content: center

.formulario input
   border: 1px solid gray
   width: 95%
   height: 50px
   border-radius: 6px
   padding: 10px

.formulario select
   border: 1px solid gray
   width: 95%
   height: 50px
   border-radius: 6px
   padding: 10px

.formulario label
   font-size: 18px
   margin-bottom: 5px

/*Poner border radius solo a la esquina superior*/
.headerFormulario
   border-bottom-left-radius: 0
   border-bottom-right-radius: 0

.headerFormulario:first-child
   border-top-left-radius: 15px
   border-top-right-radius: 15px

.headerFormulario:last-child
   border-top-left-radius: 0
   border-top-right-radius: 0

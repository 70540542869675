
.layout-contenedor-principal
  position: absolute
  height: 100%
  width: 100%
  z-index: -1
  background-color: #ededed
  //clear: both
  overflow-x: hidden

.layout-contenedor-principal2
  position: absolute
  height: 100%
  width: 100%
  z-index: -1
  background-color: #28282B
  //clear: both
  overflow-x: hidden

.layout-main
  position: absolute
  width: 100%
  height: auto
  padding-left: 310px
  padding-right: 45px
  padding-top: 30px
  padding-bottom: 30px

.back
  z-index: -1
  width: 100%
  height: 35%
  top: -1px
  position: absolute
  background-repeat: no-repeat
  background-size: cover
  background-image: url("../../assets/wave.svg")
  opacity: 0.1
  animation: wave 10s ease-in-out infinite

.back2
  z-index: -1
  width: 100%
  height: 35%
  top: -1px
  position: absolute
  background-repeat: no-repeat
  background-size: cover
  background-image: url("../../assets/wave.svg")
  opacity: 1
  animation: wave 10s ease-in-out infinite

@keyframes wave
  0%
    background-position-x: 0px

  50%
    background-position-x: -100px

  100%
    background-position-x: 0px

@media screen and ( max-width: 1000px )
  .layout-main
    padding-left: 110px

@media screen and ( max-height: 1000px )
  .back
    margin-left: 8%

@media screen and ( max-width: 1600px )
  .layout-main
    padding-left: 250px


@tailwind components;
@tailwind utilities;

:root {
  --azul: #2f80ed;
  --rojobase: #cc0819;
  --verde: #81bc00;
  --grisclaro: #f4f4f4;
  --gris: #b4b4b4;
  --negrobase: #282828;
  --blanco: #ffffff;
  --azul2: #2574eb;
  --gris2: #828282;
}

html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #E5e5e5;
  
  font-family: "Open Sans" !important;
  /* font-family: "Segoe UI" !important; */
  /* font-family: "Raleway" !important; */
}

a {
  text-decoration: none;
}


.modal-containerAbono {
   position: fixed;
   top: 25%;
   left: 37%;
   width: 40%;
   height: 100%;
   display: flex;
   justify-content: center !important;
   align-items: center;
   z-index: 9999;
   /* Asegura que el modal esté en la parte superior */
}

.modal-backgroundAbono {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   /* Color de fondo con opacidad */
}

.modal-wrapperAbono {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: relative;
   background-color: white;
   padding-bottom: 20px;
   border-radius: 15px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
   /* Sombra */
}

.input-modalAbono{
   margin-top: 20px;
   margin-bottom: 20px;
   flex: 1; /* Para que ocupe todo el espacio disponible */
   display: flex;
   justify-content: center;
   align-items: center;
}

.headerFormularioAbono{
   width: 100%;
   color: white;
   background-color: #113568;
   padding: 15px;
   margin-bottom: 10px;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
}

.formularioAbono1{
   width: 100%;
   padding: 15px 80px 15px 80px;
   display: grid;
   grid-template-columns: repeat(3, 2fr);
   gap: 20px;
   justify-content: center;
}

.formularioAbono2{
   width: 100%;
   padding: 15px 80px 40px 80px;
   display: grid;
   grid-template-columns: repeat(2, 2fr);
   gap: 20px;
   justify-content: center;
}

.formularioAbono1 input, .formularioAbono2 input, .formularioAbono1 select{
   border: 1px solid gray;
   width: 100%;
   height: 50px;
   border-radius: 6px;
   padding: 10px;

}

/*Poner border radius solo a la esquina superior*/
.headerFormularioAbono{
   border-bottom-left-radius: 0;
   border-bottom-right-radius: 0;
}

.headerFormularioAbono:first-child{
   border-top-left-radius: 14px;
   border-top-right-radius: 14px;
}

.headerFormularioAbono:last-child{
   border-top-left-radius: 0;
   border-top-right-radius: 0;
}

.modal-botonesAbono{
   display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.boton-modalAbono{
   width: 120px;
   margin-left: 20px !important;
   margin-right: 20px !important;
}

/* Agrega más estilos según sea necesario */

.campo-avalAbono{
   width: 60%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
}

.radio-modalAbono{
   display: flex;
   align-items: center;
   width: 100%;
   justify-content: center;
}

.input-avalAbono{
   width: 25% !important;
   margin-left: 10px;
}

.modal-wrapperAbono {
   display: flex;
   flex-direction: column;
   align-items: center; /* Alineación horizontal al centro */
}

.rowAbono {
   width: 100%;
   display: flex;
   justify-content: space-between;
   margin-bottom: 20px; /* Espacio entre los rows */
}

.input-rowAbono {
   display: flex;
   gap: 20px;
}

.label {
   margin-bottom: 5px;
}

.divNombreCliente{
   height: 50px;
   display: flex;
   align-items: center;
}

@media screen and ( max-width: 1750px ){
   .modal-containerAbono{
      top: 15%;
      left: 35%;
      width: 50%;
   }
}

@media screen and ( max-width: 1400px ){
   .modal-containerAbono{
      top: 15%;
      left: 28%;
      width: 60%;
   }
}
#divpaginate ul {
  display: flex;
  justify-content: right;
  padding-right: 20px;
  padding-top: 0;
}
  
#divpaginate ul li {
  background-color: #fff;
  border:  2px solid #bbb;
  padding: 5px;
  margin: 0 2px 0 2px;
}

#divpaginate ul li:hover {
  background-color: #bbb;
}

#divpaginate ul li:hover a {
  color: red;
}

#divpaginate ul li.selected {
  background-color: #113568
}

#divpaginate ul li.selected a {
  color: #fff;
}
 
#divpaginate ul li a {
  padding: 0.4rem;
  font-size: 1em;
  font-weight: bold;
  color: #113568 ;
}